<template>
  <component :is="layout">
    <template v-slot:title>Account Settings</template>
    <div class="p-0 p-md-3 bg-gray-light-4 overflow-auto h-100">
      <div class="row">
        <!-- Cards -->
        <div class="col-12 col-md-8 offset-md-2">
          <GoBackBtn v-if="$route.params.id">Back</GoBackBtn>
          <AppCard class="mb-4">
            <!-- <div class="profile-picture">
              <span>HK</span> <span>Change Profile Picture</span>
            </div> -->

            <div class="p-3">
              <AppRowInputContainer label="First Name">
                <AppBaseInput v-model="form.first_name" />
              </AppRowInputContainer>
              <AppRowInputContainer label="Last Name">
                <AppBaseInput v-model="form.last_name" />
              </AppRowInputContainer>
              <AppRowInputContainer label="Company">
                <AppBaseInput
                  :disabled="!isAdmin"
                  v-model="form.company_name"
                />
              </AppRowInputContainer>
              <AppRowInputContainer label="Company Address">
                <AppBaseInput
                  :disabled="!isAdmin"
                  v-model="form.company_address"
                />
              </AppRowInputContainer>
              <AppRowInputContainer label="Company State">
                <AppInputSelect
                  v-if="isAdmin"
                  searchable
                  :disabled="!isAdmin"
                  v-model="form.company_state"
                  :items="states"
                ></AppInputSelect>
                <AppBaseInput
                  v-else
                  :disabled="true"
                  v-model="form.company_state"
                />
              </AppRowInputContainer>
              <AppRowInputContainer label="Company City">
                <AppInputSelect
                  v-if="isAdmin"
                  searchable
                  :disabled="!isAdmin || !form.company_state"
                  v-model="form.company_city"
                  :items="mappedCities"
                ></AppInputSelect>
                <AppBaseInput
                  v-else
                  :disabled="true"
                  v-model="form.company_city"
                />
              </AppRowInputContainer>
              <AppRowInputContainer label="Company Zip Code">
                <AppBaseInput
                  :disabled="!isAdmin"
                  v-model="form.company_zip_code"
                />
              </AppRowInputContainer>
              <AppRowInputContainer label="Email Address">
                <label class="col-form-label py-0">{{ form.email }} </label>
                <!-- <AppBaseLink
                  :to="{ path: '/change-email' }"
                  class="btn btn-link text-primary py-0 px-1"
                  >Edit</AppBaseLink
                > -->
              </AppRowInputContainer>
              <AppRowInputContainer label="Phone Number">
                <AppBaseInput v-model="form.mobile_number" />
                <!-- <AppBaseLink
                  :to="{ path: '/change-number' }"
                  class="btn btn-link text-primary py-0 px-1"
                  >Edit</AppBaseLink
                > -->
              </AppRowInputContainer>
              <AppRowInputContainer label="Password">
                <label class="col-form-label py-0">*********</label>
                <AppBaseLink
                  :to="{
                    path: $route.params.id
                      ? `/change-password/${$route.params.id}`
                      : '/change-password',
                  }"
                  class="btn btn-link text-primary py-0 px-1"
                  >Change Password</AppBaseLink
                >
              </AppRowInputContainer>
              <AppRowInputContainer label="Role" v-if="isAdmin && !isMe">
                <AppInputSelect
                  v-model="form.groups"
                  multiple
                  class="w-100"
                  :items="userGroups"
                  :shouldSelectFirstItem="false"
                ></AppInputSelect>
              </AppRowInputContainer>

              <AppRowInputContainer label="Domain">
                <AppBaseInput :disabled="true" v-model="domain" />
              </AppRowInputContainer>

              <AppRowInputContainer label="Receive Email Notification">
                <AppInputCheckbox
                  v-model="form.get_order_update_email"
                  label=""
                ></AppInputCheckbox>
              </AppRowInputContainer>
            </div>
          </AppCard>
          <div class="d-flex justify-content-end">
            <AppBtn
              class="mr-2"
              :color="form.is_active ? 'danger' : 'success'"
              :loading="loading"
              v-if="isSuspendable"
              @click="confirmSuspendAccount"
            >
              {{ suspendTitle }}
            </AppBtn>
            <AppBtn
              class="mr-0"
              :disabled="!isFormDirty"
              :loading="loading"
              @click="handleSave"
            >
              Save Changes
            </AppBtn>
          </div>
        </div>
      </div>
      <ActionModal v-model="userSuspendModal" @confirm="toggleSuspendUser">
        <template v-slot:title>{{ suspendTitle }}?</template>
        <template v-slot:description> Click confirm to proceed </template>
      </ActionModal>
      <SuccessModal
        v-model="showSuccessModal"
        @confirm="showSuccessModal = false"
      >
        <template v-slot:title>SUCCESSFULLY UPDATED!</template>
        <template v-slot:description></template>
      </SuccessModal>
    </div>
  </component>
</template>

<script>
import AppCard from '@/shared/elements/AppCard.vue';
import AppInput from '../../../shared/elements/AppInput.vue';
import AppInputSelect from '../../../shared/elements/AppInputSelect.vue';
import AppRowInputContainer from '@/shared/elements/AppRowInputContainer.vue';
import AppBaseLink from '@/shared/elements/AppBaseLink.vue';
import AppBaseInput from '@/shared/elements/AppBaseInput.vue';
import AppInputCheckbox from '../../../shared/elements/AppInputCheckbox.vue';
import AppInputRadio from '../../../shared/elements/AppInputRadio.vue';
import AppMediaViewer from '../../../shared/elements/AppMediaViewer.vue';
import AppTextLabeled from '../../../shared/elements/AppTextLabeled.vue';
import AppBtn from '../../../shared/elements/AppBtn.vue';
import AppIcon from '../../../shared/elements/AppIcon.vue';
import PersonaConstants from '@/shared/constants/PersonaConstants';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';
import * as apiUser from '@/shared/api/Users';
import { mapGetters, mapMutations } from 'vuex';
import { isRoleAllowed } from '@/router/AuthorizationGate';
import { group_names } from '@/shared/constants/PersonaConstants';
import states from '@/shared/constants/states.json';

const DEFAULT_FORM = () => ({
  first_name: '',
  last_name: '',
  email: '',
  mobile_number: '',
  company_name: '',
  company_address: '',
  company_city: '',
  company_state: '',
  company_zip_code: '',
  profile_picture: '',
  get_order_update_text: false,
  get_order_update_email: false,
  get_marketing_update_text: false,
  get_marketing_update_email: false,
  groups: [],
});

export default {
  components: {
    AppCard,
    AppInput,
    AppInputSelect,
    AppBaseLink,
    AppInputCheckbox,
    AppMediaViewer,
    AppTextLabeled,
    AppInputRadio,
    AppBtn,
    AppBaseInput,
    AppRowInputContainer,
    AppIcon,
    SuccessModal,
    GoBackBtn,
    ActionModal,
    LayoutSuper: () =>
      import('@/components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin'),
    LayoutVendor: () =>
      import('@/components/Partial/Layouts/LayoutVendor/LayoutVendor'),
    LayoutBuyer: () =>
      import('@/components/Partial/Layouts/LayoutBuyer/LayoutBuyer'),
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),

    isAdmin() {
      const { admin, owner } = group_names;

      return isRoleAllowed([admin, owner]);
    },

    isMe() {
      const id = Number(this.$route.params.id);
      return this.user.id === id || this.$route.path === '/account';
    },

    isSuspendable() {
      return (this.isAdmin && !this.isMe) && this.form.is_approved;
    },

    layout() {
      const { persona } = this.user || '';
      let layoutName = 'LayoutBuyer';

      switch (persona) {
        case PersonaConstants.super.value:
          layoutName = 'LayoutSuper';
          break;
        case PersonaConstants.vendor.value:
          layoutName = 'LayoutVendor';
          break;
        case PersonaConstants.buyer.value:
          layoutName = 'LayoutBuyer';
          break;
        default:
          layoutName = 'LayoutBuyer';
          break;
      }

      return layoutName;
    },

    suspendTitle() {
      const title = this.form.is_active ? 'Deactivate' : 'Activate';

      return `${title} User`;
    },

    isFormDirty() {
      return JSON.stringify(this.form) !== JSON.stringify(this.cleanForm);
    },

    mappedCities() {
      if (!this.form.company_state) {
        return [];
      }

      const state = states.find((s) => s.value === this.form.company_state);

      if (!state) {
        return [];
      }

      return state.cities;
    },
  },

  data() {
    return {
      form: DEFAULT_FORM(),
      cleanForm: DEFAULT_FORM(),
      loading: false,
      showSuccessModal: false,
      userSuspendModal: false,
      states,
      domain: '',
      userGroups: [],
    };
  },

  async mounted() {
    let user = this.user;
    const id = this.$route.params.id;

    if (id) {
      const userResponse = await apiUser.getUser(id);
      user = userResponse.data;
    }

    await this.fetchUserGroup(user);

    this.form = {
      first_name: user?.information?.first_name,
      last_name: user?.information?.last_name,
      is_active: user?.is_active,
      email: user.email,
      mobile_number: user.mobile_number,
      company_name: user?.company?.name,
      company_address: user?.company?.address,
      company_city: user?.company?.city,
      company_state: user?.company?.state,
      company_zip_code: user?.company?.zip_code,
      profile_picture: user?.information?.profile_picture,
      get_order_update_text: Boolean(user?.information?.get_order_update_text),
      get_order_update_email: Boolean(
        user?.information?.get_order_update_email
      ),
      get_marketing_update_text: Boolean(
        user?.information?.get_marketing_update_text
      ),
      get_marketing_update_email: Boolean(
        user?.information?.get_marketing_update_email
      ),
      is_approved: user?.is_approved,
      groups: user?.groups?.map(a => a?.id),
    };

    this.domain = user?.company?.domain ? `@${user?.company?.domain}` : 'No Domain';

    this.cleanForm = { ...this.form };
  },

  methods: {
    ...mapMutations('auth', {
      setUser: 'SET_USER',
    }),
    async handleSave() {
      this.loading = true;
      let id = this.user.id;

      if (this.$route.params.id) {
        id = this.$route.params.id;
      }

      const {
        get_order_update_text,
        get_order_update_email,
        get_marketing_update_text,
        get_marketing_update_email,
        ...form
      } = this.form;
      const updateResponse = await apiUser.updateUser(id, {
        _method: 'PUT',
        ...form,
        company_details: {
          name: form.company_name,
          address: form.company_address,
          city: form.company_city,
          state: form.company_state,
          zip_code: form.company_zip_code,
        },
        get_order_update_text: Number(get_order_update_text),
        get_order_update_email: Number(get_order_update_email),
        get_marketing_update_text: Number(get_marketing_update_text),
        get_marketing_update_email: Number(get_marketing_update_email),
      });

      if (updateResponse.status === 200) {
        const userResponse = await apiUser.getUser(id);

        if (id === this.user.id) {
          this.setUser({
            ...userResponse.data,
            user_group: this.user.user_group,
            userGroups: this.user.userGroups,
          });
        }
      }
      this.showSuccessModal = true;
      this.loading = false;
    },

    confirmSuspendAccount() {
      this.userSuspendModal = true;
    },

    async toggleSuspendUser() {
      this.loading = true;
      let id = this.user.id;

      if (this.$route.params.id) {
        id = this.$route.params.id;
      }

      const updateResponse = await apiUser.updateUser(id, {
        _method: 'PUT',
        is_active: this.form.is_active === 0 ? 1 : 0,
      });

      if (updateResponse.status === 200) {
        const userResponse = await apiUser.getUser(id);

        if (id === this.user.id) {
          this.setUser({
            ...userResponse.data,
            user_group: this.user.user_group,
            userGroups: this.user.userGroups,
          });
        }

        this.form = {
          ...DEFAULT_FORM(),
          ...userResponse.data,
          company_name: userResponse.data.company.name,
          company_address: userResponse.data.company.address,
          company_city: userResponse.data.company.city,
          company_state: userResponse.data.company.state,
          company_zip_code: userResponse.data.company.zip_code,
        };
      }

      this.showSuccessModal = true;
      this.loading = false;
    },

    async fetchUserGroup(user) {
      const companyType = user.persona;
      const persona = PersonaConstants[companyType];

      if (persona && persona.group_names) {
        const owner = persona.group_names.find((group) => group.value === 'owner');
        const response = await apiUser.getUserGroups();
        const isUserOwner = user.groups.some((g) => g.name === 'Owner');

        this.userGroups = response.data
          .filter(
            (group) => {
              if (isUserOwner) return persona.group_names.find((constGroup) => constGroup.name === group.name);

              return group.name !== owner.name && persona.group_names.find((constGroup) => constGroup.name === group.name)
            }
          )
          .map((group) => ({
            text: group.name,
            value: group.id,
          }));
      }
    },
  },
};
</script>
